<template>

<div id="container">

  <b-alert
      :show="dismissCountDown"
      fade
      variant="danger"
      @dismiss-count-down="countDownChanged"
  >
      Error : {{errorMessage}}
  </b-alert>  


  <div id="header">
    <admin-header-section />
  </div>
  <div id="main">
    <div class="adminContainer">
      <h4>Admin POS Wines <b-button size="sm" variant="success" v-on:click="addRecord()">Add New</b-button></h4>
      <br/>

      
      <div class="adminScroll">
        
        <b-table-simple responsive class="dataTable">
          <b-thead>
            <b-tr class="datalistHeader">
              <b-th>Wine Colour</b-th>
              <b-th>Bottle size</b-th>
              <b-th>Year</b-th>
              <b-th>Name</b-th>
              <b-th>Size</b-th>
              <b-th>Colour</b-th>
              <b-th>Position</b-th>
              <b-th>Price 25ml</b-th>
              <b-th>Price 75ml</b-th>
              <b-th>Price 125ml</b-th>
              <b-th>Price 175ml</b-th>
              <b-th>Price bottle</b-th>
              <b-th></b-th>
              <b-th></b-th>            
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr  :key="index" v-for="col,index in data" class="datalistBody"  v-bind:class="{ isHidden: data[index].hidden }">
              <b-td>{{data[index].wine_color}}</b-td>
              <b-td>{{data[index].bottle_size}}</b-td>
              <b-td>{{data[index].year}}</b-td>
              <b-td>{{data[index].name}}
                  <span v-if="data[index].notes">
                    <br/><i>{{data[index].notes}}</i>
                    </span></b-td>
              <b-td>{{getSize(data[index].size)}}</b-td>
              <b-td  :style="{backgroundColor:getFoodColorBackground(data[index].color),color:getFoodColorForground(data[index].color)}">{{getColor(data[index].color)}}</b-td>
              <b-td>{{data[index].position}}</b-td>
              <b-td>{{data[index].price_25}}</b-td>
              <b-td>{{data[index].price_75}}</b-td>
              <b-td>{{data[index].price_125}}</b-td>
              <b-td>{{data[index].price_175}}</b-td>
              <b-td>{{data[index].price_bottle}}</b-td>
              <b-td><b-button size="sm" variant="success" v-on:click="editRecord(data[index])">Edit</b-button></b-td>            
              <b-td><b-button size="sm" variant="warning" v-on:click="hideRecord(data[index])"><span v-if="data[index].hidden">Un Hide</span><span v-id="!data[index].hidden">Hide</span></b-button></b-td>            
              <b-td><b-button size="sm" variant="danger" v-on:click="deleteRecord(data[index])">Delete</b-button></b-td>            
            </b-tr>
          </b-tbody>        

        </b-table-simple>
        <h4 v-if="data.length===0">
            No Food
        </h4>      
      </div>
    </div>
  </div>  

  <form-modal 
    v-on:onSaveClick="onSaveClick"
    :record="record"
    :showdialog="showModal"
  /> 


</div>

</template>


<script>

import AdminHeaderSection from '../AdminHeaderSection.vue';
import FormModal from './formModal.vue';
import { HTTP } from '@/plugins/http-common';
import defaults from '@/plugins/defaults';


export default {
  name: 'Admin',
  components:{
    AdminHeaderSection,
    FormModal,
  },
  mounted() {
      this.getData()
  },
  data () {  
      return {
            fields: ['name','price','color','size'],
            data:[],
            errorMessage:'',
            dismissCountDown:0,            
            record:{
              name:'',
            },
            showModal:false,
            colors:defaults.COLORS,
            size:defaults.SIZES 
      };
  },
  methods: { 
      rowClass() {
        return 'dataTableRow'
      },    
    addRecord() {

      var tempPosition = 0;

      this.data.forEach(item => {

        if (parseInt(item.position) > tempPosition) {
          tempPosition = parseInt(item.position);
        }
        
      });

      this.record = {
          id: -1,
          name: '',
          price: 0,
          color: 0,
          size: 1,
          position: '' + (tempPosition+1),
          column:0
        }
        this.showModal =true;
    },
    editRecord(record) {
      this.record = record;
      this.showModal =true;
    },
    deleteRecord(record) {
      HTTP.delete(
          'api/admin/wine/delete/'+record.id,
          {
              headers: {
                  Authorization: 'Bearer ' + localStorage.token
              }
          }   
          )
      .then(response => {
          
            console.log(response);
            this.getData();

      })
      .catch(e => {

          this.dismissCountDown = 5;
          this.errorMessage = 'Sorry Error Deleting : ' + e;

      })            
    },    
    hideRecord(record) {
      HTTP.delete(
          'api/admin/wine/hide/'+record.id,
          {
              headers: {
                  Authorization: 'Bearer ' + localStorage.token
              }
          }   
          )
      .then(response => {
          
            console.log(response);
            this.getData();

      })
      .catch(e => {

          this.dismissCountDown = 5;
          this.errorMessage = 'Sorry Error Deleting : ' + e;

      })            
    },    
    getSize(sizeID) {
      var size = parseInt(sizeID);
      if (size == 0) { size=1;}
      if ( size > this.size.length) { size = 0 } else { size = size - 1;}      
      var sourceListResult  = this.size[size];
      return sourceListResult.name;
    },
    getColor(colorID) {
      var color = parseInt(colorID);
      if ( color > this.colors.length) { color = 0 } else { color = color - 1;}      
      var sourceListResult  = this.colors[color];
      return sourceListResult.name;
    },
    getFoodColorBackground(colorID) {
        var color = parseInt(colorID);
        if ( color > this.colors.length) { color = 0 } else { color = color - 1;}
        return this.colors[color].back + " !important";
    },    
    getFoodColorForground(colorID) {
        var color = parseInt(colorID);
        if ( color > this.colors.length) { color = 0 } else { color = color - 1;}
        return this.colors[color].color + " !important";
    },       
    getData() {
        HTTP.get(
            'api/admin/wine',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token
                }
            }   
            )
        .then(response => {
            
            console.log(response.data)
            this.data = response.data;

        })
        .catch(e => {

            console.log(e);

            this.$notify({
                group: 'main',
                title: 'Sorry Error Getting Food data',
                type: 'warn',
                position: 'bottom left'
            });        

        })                
    },
    onSaveClick (value) {
      this.showModal = false;

      if (value.id < 0) {
          HTTP.post(
            'api/admin/wine/create',
            {
              form: value
            },
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token
                }
            }             
            )
          .then(response => {
            
            console.log(response);
            this.getData();

          })
          .catch(e => {

            this.dismissCountDown = 5;
            this.errorMessage = 'Invalid Save' + e;

            this.$nextTick(() => {
              this.loginModalVisible = true;
              this.wineListVisble =false;          
            })        
          })
      } else {

        HTTP.post(
          'api/admin/wine/update',
          {
            form: value
          },
          {
              headers: {
                  Authorization: 'Bearer ' + localStorage.token
              }
          }             
          )
        .then(response => {
          
          console.log(response);
          this.getData();

        })
        .catch(e => {

          this.dismissCountDown = 5;
          this.errorMessage = 'Invalid Save' + e;

          this.$nextTick(() => {
            this.loginModalVisible = true;
            this.wineListVisble =false;          
          })        
        })


      }


    },    
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },    
  },
  computed: {
 
  }  
}      


</script>

<style>

  body {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    background-color: #131720;
    margin: 0px;
    padding: 0px;
    font-size: 0.8rem;
    overflow: hidden;     
    border:5px solid #131720;
  }

  .admin {
    color:white;
    width:100%;
  }

  .adminScroll{
    height: 70vh;
    overflow-x: hidden;
    overflow-y: auto;    
  }
  .adminContainer {
    height: calc(100%);
    width: 90%;
    margin:auto;
  }
  .adminContainer h4 {
    color:white;
  }
  .adminholderSquare {
    position: relative;
    width: 100%;
    border-radius: 10px;
    background-color: #2e3444;
    height: 20vh !important;
    overflow: hidden;
  }

  .adminholderSquare:after {
    content: "";
    display: block;
    padding-bottom: 100%;
    background-color: #2e3444;    
    margin:30px;
  }

  .adminholderContentholder {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .adminholderDescptionHolder {
    border: 1vw solid transparent;
    min-height: 20vh;
    font-size: 1.6vw;
    font-weight: 700;
    line-height: 1.6vw;   
  }
  .adminOptionRow {
    height: 20vh !important;

  }
  .dataTableRow {
    color:white !important;
  }
  .adminOptionRow a {
    color:white;
  }
  .datalistTable {
      width: 100%;
  }

    .datalistpadded {
        border:1vh solid transparent;
        width:100%;
    }
    .datalistpadded h4 {
      color:white;
    }
    .datalistHeader {
        color:white;
    }
    .datalistBody {
      color:white;
      font-size:2vh;
    }
    .datalistBody tr:hover {
      color:white !important;
      background-color: blue !important;
    }
    .datalistActionButton {
        background-color: #b0996f !important;
        color: white !important;
        border-color: #b0996f !important;
        font-size: 1rem !important;
    }   
    .isHidden {
      text-decoration: line-through;
    }  

</style>